<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Wyszukaj po tytule lub kategorii" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        :to="{ name: 'inspirations-create' }"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="btn">
        Dodaj
      </b-button>
    </div>

    <b-table
      responsive
      bordered
      striped
      hover
      :items="inspirations"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(parent)="row">
        <b-link :to="{ name: 'blog-posts-edit', params: { slug: row.item.parent.slug } }" v-if="row.item.parent">
          {{ row.item.parent.title }}
        </b-link>
      </template>
      <template #cell(actions)="row">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <b-button
            :to="{ name: 'inspirations-edit', params: { slug: row.item.slug, lang: row.item.lang } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Edytuj
          </b-button>
          <b-button class="mt-1 table-btn" variant="danger" size="sm" @click="archive(row.item)"> Usuń </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, destroy } from '@/http/inspirations';

import { LanguageFormatter } from '@/formatters/LanguageFormatter';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      inspirations: [],

      filter: null,
      filterOn: ['name', 'slug'],
      fields: [
        {
          key: 'name',
          label: 'Tytuł',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'lang',
          label: 'Język',
          sortable: true,
          sortDirection: 'desc',
          formatter: (val) => {
            return LanguageFormatter(val);
          },
        },
        {
          key: 'slug',
          label: 'Slug',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'actions', label: 'Akcje', class: 'w-25' },
      ],
    };
  },

  async created() {
    await this.fetchInspirations();
  },

  methods: {
    async fetchInspirations() {
      try {
        const { data } = await all();

        this.inspirations = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem postów. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async archive(post) {
      this.$swal({
        title: 'Jesteś pewny?',
        text: 'Czy chcesz usunąć inspirację?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        cancelButtonText: 'Anuluj',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          destroy(post.slug)
            .then(() => {
              this.showSuccessNotification('Usunięto inspirację', 'Inspiracja została usunięta.');
              this.fetchInspirations();
            })
            .catch(() => {
              this.showErrorNotification(
                'Problem z usunięciem inspiracji',
                'Wystąpił problem z usunięciem inspiracji. Skontaktuj się ze swoim developerem.',
              );
            });
        }
      });
    },
  },
};
</script>
